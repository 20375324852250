
import { defineComponent, onMounted, ref, watch, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { addAgendamento, getHorariosOcupadosMecanicos} from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { config } from "@/views/agenda-virtual/ConfigEvents";
import { useStore } from "vuex";
import { ServicoSolicitado } from "@/models/AgendaModel"
import { ConcessionariaModel } from "@/models/ConcessionariaModel";
import { MecanicoModel } from "@/models/MecanicoModel";
import { ConsultorModel } from "@/models/ConsultorModel";
import { PropType } from "vue";
import {tarefas} from "@/views/agenda-virtual/Tarefas"

interface Option {
  text: string,
  value: string | number
}

interface Form {
  codOrcamento: string[] | number[]
  servicosDaOS: Array<any>
  placa: string,
  modelo: string,
  codConsultor: string,
  numeroOS: string,
  cliente: string,
  chassi: string
  dataRecepcao: string | null
  dataPrevisao: string | null
  codAgendamento: string | null
  orcamentoSelecionado : string[]
}

interface initialForm {
  codAgendamento: string | null,
  detalhes: string,
  cliente: string,
  placa: string,
  taskType: string,
  taskDate: string,
  taskEndDate: string,
  taskStartHour: string,
  taskEndHour: string | null,
  taskHourEntrega: string | null,
  taskHourRecepcao: string,
  dataRecepcao: string | null
  dataPrevisao: string | null
  tecnico: string,
  consultorAgendado: string,
  dataAgendamento: string,
  marca: string,
  chassi: string,
  modelo: string,
  osAberta: string,
}

export default defineComponent({
  name: "AdicionarServicoOrcamentoComplementar",
  props: {
    initialForm: Object as PropType<Form>,
  },
  emits: ["success"],
  setup(props, { emit }) {
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingOs = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const concessionaria = ref<ConcessionariaModel | undefined>(store.getters.concessionariaSelectedInfo)
    const targetData = ref<initialForm>({
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      taskType: "",
      taskDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      taskEndDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      dataRecepcao: "",
      dataPrevisao: "",
      tecnico: "",
      consultorAgendado: '',
      dataAgendamento: "",
      marca: "",
      chassi: "",
      modelo: "",
      osAberta: "",
    });
    const horarios = reactive({
      horaAbertura: '08:00',
      horaFechamento: '19:00',
    })
    const defaultInitialTimes: any = ref(geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento));
    const defaultFinalTimes: any = ref([]);
    const tecnicoOptions = ref<Option[]>([]);
    const consultorOptions = ref<Option[]>([]);
    const horariosOcupados : any = ref([]);
    const servicosAgendado = ref<ServicoSolicitado[] | []>([])
    const tipoOptions = tarefas
    const orcamentoSelecionado = ref<string[] | []>([])
    const rules = ref({
      consultorAgendado: [
        {
          required: true,
          message: "Selecione o consultor responsável pelo serviço",
          trigger: "blur",
        },
      ],
      placa: [
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value === '' && targetData.value.chassi === '') {
              callback(new Error('Digite a placa do carro ou chassi'))
            } else {
              callback()
            }
          },
          message: "Digite a placa do carro ou chassi",
          trigger: "blur",
        },
      ],
      modelo: [
        {
          required: true,
          message: "Digite o modelo do carro",
          trigger: "blur",
        },
      ],
      tecnico: [
        {
          required: true,
          message: "Selecione o técnico responsável pelo serviço",
          trigger: "blur",
        },
      ],
      taskType: [
        {
          required: true,
          message: "Selecione o tipo de agendamento",
          trigger: "blur",
        },
      ],
      taskDate: [
        {
          required: true,
          message: "Selecione a data do agendamento",
          trigger: "blur",
        },
      ],
      taskStartHour: [
        {
          required: true,
          message: "Selecione o horário inicial de realização do serviço",
          trigger: "blur",
        },
      ],
      taskEndHour: [
        {
          required: true,
          message: "Selecione o horário final de realização do serviço",
          trigger: "blur",
        },
      ],
      taskHourEntrega: [
        {
          required: true,
          message: "Selecione o horário de recepção do cliente",
          trigger: "blur"
        }
      ]
    });

    watch(() => props.initialForm, async () => {
      loading.value = false
      servicosAgendado.value = []; 

      resetProgrammingTime()

      if(props.initialForm){

        targetData.value = { ...targetData.value, ...props.initialForm };
      
        targetData.value.consultorAgendado = props.initialForm?.codConsultor 
     
        targetData.value.osAberta = props.initialForm?.numeroOS      
    
        servicosAgendado.value = props.initialForm?.servicosDaOS ?? []

        const dataHora = moment(props.initialForm?.dataPrevisao);
        const hora = dataHora.format('HH:mm');

        targetData.value.taskHourEntrega = hora

        orcamentoSelecionado.value = props.initialForm?.orcamentoSelecionado
      }

      verificaHorariosOcupados();
      verificaHorarioInicialRecepcao();
      verificaHorarioInicial();
    }, { deep: true });

    onMounted(() => {
      setConcessionaria()
      horariosQuadro()
      mecanicos()
      consultores()
    });

    const resetProgrammingTime = () =>  {
      targetData.value.taskStartHour = ''
      targetData.value.taskHourEntrega = ''
      targetData.value.taskEndHour = ''
    }
      
    const  horariosQuadro = () => {
      const concessionaria = store.getters.concessionariaSelectedInfo
      if (!(Object.keys(concessionaria).length === 0)) {
        horarios.horaAbertura = moment(concessionaria.horaAbertura, "H").format("HH:mm")
        horarios.horaFechamento = moment(concessionaria.horaFechamento, "H").format("HH:mm")
        defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento)
      }
    }

    const mecanicos = (): void => {
      if (store.getters.mecanicosList.length > 0) {
        const mecanicos: MecanicoModel[] = store.getters.mecanicosList;

        tecnicoOptions.value = mecanicos.map(tecnico => {
          const option = {
            text: tecnico.nome,
            value: tecnico.codMecanico
          }

          return option;
        });
      }
    }

    const consultores = (): void => {
      if (store.getters.consultoresList.length > 0) {
       
        const consultores: ConsultorModel[] = store.getters.consultoresList;
        let auxConsultores: Option[] = consultores.map(consultor => {
          const option = {
            text: consultor.nome,
            value: consultor.intConsultor ?? removeAcentos(consultor.nome).toUpperCase()
          }

          return option;
        });

        auxConsultores.push({
          text: "Outros funcionários",
          value: "OUTROS FUNCIONARIOS",
        });

        consultorOptions.value = [...auxConsultores];
      }
    }

    const setConcessionaria = (): void => {
      if (store.getters.concessionariaSelectedInfo) {
        concessionaria.value = store.getters.concessionariaSelectedInfo
      }
    }

    watch(() => targetData.value.taskType, () => {
      if (targetData.value.taskType) {
        const refOption = tipoOptions.find(option => option.value === Number(targetData.value.taskType));
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    store.watch(() => store.getters.consultoresList, () => { consultores() }, { deep: true })
    store.watch(() => store.getters.concessionariaSelectedInfo, () => { setConcessionaria() }, { deep: true })
    store.watch(() => store.getters.concessionariasList, () => { horariosQuadro() })
    store.watch(() => store.getters.mecanicosList, () => { mecanicos() }, { deep: true });

    function geraDefaultTimes(minTime = "07:00", maxTime = "19:00", step = 15, showLastTime = false) {
      const refTrueLastTime = moment(maxTime, "HH:mm");
      let refLastTime = refTrueLastTime.clone();
      if (!showLastTime) {
        refLastTime.subtract(step, "minutes");
      }

      let auxDefaultTimes: any = [];
      let auxTime = moment(minTime, "HH:mm");
      auxTime.subtract(step, "minutes");

      while (auxTime.clone().isBefore(refLastTime)) {
        auxTime.add(step, "minutes");
        const nextTime = auxTime.clone();

        if (nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))) {
          auxDefaultTimes.push({
            text: nextTime.format("HH:mm"),
            value: nextTime.format("HH:mm"),
            disabled: false
          });
        }
      }

      return auxDefaultTimes;
    }

    const  closeModal = () : void => {
      const modal = document.getElementById('agendar-servico-orcamento-complementar');
      hideModal(modal);
    };

    function removeAcentos(name, toLowerCase = false) {
      if (!name) {
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };


    async function verificaHorariosOcupados() {
      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial

      const dataInicialFormatada = dataInicial.format("YYYY-MM-DD");

      try {
        const response = await getHorariosOcupadosMecanicos(concessionaria.value?.codConcessionaria, dataInicialFormatada);
        horariosOcupados.value = response;
      } catch (error) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
        closeModal()
      }

      desabilitaHorariosOcupados();
    };

    async function desabilitaHorariosOcupados() {
      defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
      verificaHorarioInicial();

      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      //caso a data inicial for maior que a data final
      if (dataInicial.isAfter(dataFinal)) {
        targetData.value.taskEndDate = targetData.value.taskDate
      }   

      let auxHorarioFinalOcupado = false;
      if (targetData.value.tecnico) {
        const horariosOcupadosMecanicoSelecionadoDataInicial = horariosOcupados.value.filter(horario => horario.codTecnico == targetData.value.tecnico);

        const dataComparacao = moment(targetData.value.taskEndDate).startOf('day');

        const todasIguais = horariosOcupadosMecanicoSelecionadoDataInicial.every(item => {
          const dataFinalServico = moment(item.dataFinalServico).startOf('day');
          return dataFinalServico.isSame(dataComparacao);
        });


        horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
          let auxStartTime: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
          auxStartTime = moment(auxStartTime, "HH:mm");

          let auxEndTime: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
          auxEndTime = moment(auxEndTime, "HH:mm");

          const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
            if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
              return {
                ...time,
                disabled: true
              };
            } else {
              return time;
            }
          })
          defaultInitialTimes.value = auxDefaultInitialTimes;

          if (!auxHorarioFinalOcupado) {
            defaultFinalTimes.value = defaultFinalTimes.value.map(time => {
              if (auxHorarioFinalOcupado || horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]")) {
                auxHorarioFinalOcupado = true;

                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            }).filter(time => !time.disabled);
          }

          if (todasIguais) {
            if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(targetData.value.taskStartHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
              targetData.value.taskStartHour = "";
              targetData.value.taskEndHour = "";
            } else if (
              moment(targetData.value.taskEndHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]") ||
              (auxEndTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "(]") &&
                auxStartTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "[)")
              )
            ) {
              targetData.value.taskEndHour = "";
            }
          }
        })
      }
    }

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");

      defaultFinalTimes.value = geraDefaultTimes(auxStartHour.clone().add(15, "minutes").format("HH:mm"), horarios.horaFechamento, 15, true);

      if (endHour && endHour.isBefore(startHour)) {
        targetData.value.taskEndHour = null;
      }
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");
      initialHourEntrega.value = auxStartHour.clone().add(15, "minutes").format("HH:mm");

      if (endHour && endHour.isBefore(startHour)) {
        targetData.value.taskHourEntrega = null;
      }
    }    

    async function addNewTask(postData) {
      try {
        const response = await addAgendamento(postData);

        if (response.ocupado) {
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11) {
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });

          return;
        }

        showTimeAlert("Agendamento realizado com sucesso!")
        closeModal();
      } catch (e) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loading.value = false;
      }
    }

    function desabilitaDatasPassadas(data) {
      return moment(data).diff(moment(), 'days') < 0;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;          

          const dataTratadaInicial = moment(targetData.value.taskDate).format("YYYY-MM-DD");
          const postData = {
            cnpjConcessionaria: concessionaria.value?.cnpj,
            codConcessionaria: concessionaria.value?.codConcessionaria,
            codAgendamento: targetData.value.codAgendamento,
            codTipoServico: targetData.value.taskType,
            dataInicioServico: moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
            dataFinalServico: moment(dataTratadaInicial + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
            dataRecepcao: targetData.value.dataRecepcao,
            dataEntrega: targetData.value.taskHourEntrega ? moment(dataTratadaInicial + " " + targetData.value.taskHourEntrega).format("YYYY-MM-DD HH:mm:ss") : dataTratadaInicial + " " + '00:00:00',
            codTecnico: targetData.value.tecnico,
            cliente: targetData.value.cliente,
            placa: targetData.value.placa,
            chassi: targetData.value.chassi,
            dataAgendamento: moment(dataTratadaInicial + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
            marca: targetData.value.marca.toUpperCase(),
            modelo: targetData.value.modelo.toUpperCase(),
            consultorAgendado: targetData.value.consultorAgendado,
            osAberta: targetData.value.osAberta,
            observacao: targetData.value.detalhes,
            codOrcamento: props.initialForm?.codOrcamento
          }

          addNewTask(postData);

          emit("success");
        }
      });
    };

    return {
      tecnicoOptions,
      formRef,
      newTargetModalRef,
      loading,
      targetData,
      showContent,
      rules,
      submit,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      desabilitaDatasPassadas,
      consultorOptions,
      loadingOs,      
      verificaHorariosOcupados,
      defaultInitialTimes,
      defaultFinalTimes,
      desabilitaHorariosOcupados,
      servicosAgendado,
      orcamentoSelecionado
    };
  },
});
