import {AgendamentoServicosDms } from "@/models/AgendaModel"
import { useStore } from "vuex";
import { ref } from "vue";
import {getBaseAgendamento} from '@/services/AgendaService'

export const useAppointmentsDayBefore = () => {
    const appointmentsDayBefore = ref<AgendamentoServicosDms[] | []>([]);
    const store = useStore();
    
    const getAppointmentsDayBefore = async () => {

        if(store){
            const concessionariaInfo = store.getters.concessionariaSelectedInfo;
        
            const response = await getBaseAgendamento(concessionariaInfo.codConcessionaria, -1);
            appointmentsDayBefore.value = response
                .filter(agendamento => 
                    agendamento.servicosAgendados.length === 0 &&
                    agendamento.servicosAgendados.length == 0 &&
                    agendamento.placa || agendamento.chassi
                );
        }      
    };

    return {
        appointmentsDayBefore,
        getAppointmentsDayBefore
    };
};


