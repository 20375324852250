
import FiltroDataModal from "@/views/agenda-virtual/components/modais/agendamentos/components/FiltroDataModal.vue";
import AdicionarServicoModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoModal.vue";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import AdicionarServicoPassanteModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoPassanteModal.vue";

import { defineComponent, onMounted, reactive, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import moment, { Moment } from "moment";
import { consultaOsAgenda } from "@/services/AgendaService";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert";
import { ContentLoader } from 'vue-content-loader';
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { GetOsPassante } from "@/models/AgendaModel";
import { ConcessionariaModel } from "@/models/ConcessionariaModel";
import { AgendamentoServicosDms } from "@/models/AgendaModel";


interface ServicePassingForm {
  codAgendamento: string,
  detalhes: string,
  taskType: string,
  cliente: string,
  placa: string,
  chassi: string,
  taskHourEntrega: string,
  taskHourRecepcao: string,
  formType: string,
  taskEndHour: string,
  taskDateTratada: Moment,
  taskDate: Moment,
  taskStartHour: string,
  cnpjConcessionaria: string,
  codConcessionaria: String,
  dataAgendamento: string,
  marca: string,
  modelo: string,
  consultorAgendado: string,
  osAberta: string,
  servicoAgendado: any
}

interface GetOsPassanteBuscarOS extends GetOsPassante {
  buttonLoading: boolean
}

export default defineComponent({
  name: "new-event-modal",
  components: {
    FiltroDataModal,
    AdicionarServicoModal,
    AdicionarServicoPassanteModal,
    VisualizarServicoModal,
    EditarServicoModal,
    ContentLoader    
  },
  emits: ['success'],
  setup() {
    const store = useStore();
    const { showTimeAlert } = useAlert();
    const agendamentos = ref<AgendamentoServicosDms[]>([]);
    const initialForm = ref();
    const loading = ref(false);
    const refModalNew: any = ref(null);
    const refModalNewWithoutRef: any = ref(null);
    const dataMesAtual = moment().startOf("day");
    const dataMesAtualFormatada = dataMesAtual.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de");
    const dataFiltrada = ref(dataMesAtualFormatada);
    const concessionariaInfo = ref<ConcessionariaModel>();
    const passingVehicles = ref<GetOsPassanteBuscarOS[]>([])
    const formServicePassing = reactive<ServicePassingForm>({
      codAgendamento: '',
      detalhes: '',
      taskType: '',
      cliente: '',
      placa: '',
      chassi: '',
      taskHourEntrega: '',
      taskHourRecepcao: '',
      formType: '',
      taskEndHour: '',
      taskDateTratada: moment(),
      taskDate: moment(),
      taskStartHour: '',
      cnpjConcessionaria: '',
      codConcessionaria: '',
      dataAgendamento: '',
      marca: '',
      modelo: '',
      consultorAgendado: '',
      osAberta: '',
      servicoAgendado: {}
    })

    onMounted(() => {
      refModalNew.value = new Modal(document.getElementById("kt_modal_add_event_agendamento-modal-os-aberta"));
      refModalNewWithoutRef.value = new Modal(document.getElementById("kt_modal_add_without_ref_event_agendamento-os-aberta"));
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    });

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.baseServicoPassanteList, () => {
      passingVehicles.value = store.getters.baseServicoPassanteList.map(servico => {
        return {
          ...servico,
          buttonLoading: true
        }
      })
    }, { deep: true });

    store.watch(() => store.getters.baseAgendamentosList, () => {
      getAgendamentos(store.getters.baseAgendamentosList);
    }, { deep: true });

    function openModalServicosAgendados() {
      const modal = new Modal(document.getElementById("kt_modal_select_agendamento_realizados"));
      modal.show();
    };

    function closeModalServicosAgendados() {
      const modal = document.getElementById("kt_modal_select_agendamento_realizados");
      hideModal(modal);
    };

    async function emiteSuccess() {
      loading.value = true;
      await Promise.all([
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS),
        store.dispatch(Actions.SET_BASE_SERVICOS),
        store.dispatch(Actions.SET_SERVICO_PASSANTE)
      ]);
      loading.value = false      
    };

    async function getAgendamentos(refBase: AgendamentoServicosDms[]) {
      try {
        loading.value = true;
        const baseAgendamentosList = refBase
          .filter(agendamento => agendamento.idagendamento || agendamento.placa)
          .sort((a, b) => moment(a.horaagendada, 'hh:mm').diff(moment(b.horaagendada, 'hh:mm')));

        agendamentos.value = baseAgendamentosList.filter(agendamento => agendamento.osaberta && agendamento.servicosAgendados.length == 0)
          .map(agendamento => {
            return {
              ...agendamento,
              cliente: agendamento.cliente ?? '',
              consultor_agendado: agendamento.consultor_agendado ?? '',
              dataagendamento: agendamento.dataagendamento && agendamento.horaagendada ? `${agendamento.dataagendamento} ${agendamento.horaagendada}` : ''
            }
          });
        loading.value = false;
      } catch (e) {
        console.error(e);
        showTimeAlert('Não foi possível completar a solicitação', "error");
        loading.value = false;
      } finally {
        loading.value = false;
      }
    }

    async function newServicePassing(service: GetOsPassante, index: number) {

      if (service.nos && concessionariaInfo.value) {
        const payload = {
          numeroOs: service.nos,
          codConcessionaria: concessionariaInfo.value.codConcessionaria
        }
        passingVehicles.value[index].buttonLoading = false
        const response = await consultaOsAgenda(payload).catch(() => showTimeAlert("Algo deu Errado", "error"))

        if(response){
          if(response.osEncontrada){
            formServicePassing.chassi = response.chassi ?? ''
            formServicePassing.cliente = response.cliente ?? ''
            formServicePassing.modelo = response.modelo ?? ''
            formServicePassing.placa = response.placa ?? ''
            formServicePassing.codConcessionaria = concessionariaInfo.value.codConcessionaria
            formServicePassing.osAberta = service.nos.toString()
            formServicePassing.consultorAgendado = service.intConsultor
            formServicePassing.servicoAgendado = response.servicos;
            formServicePassing.formType = "New"
            formServicePassing.cnpjConcessionaria = concessionariaInfo.value.cnpj.toString()
            refModalNewWithoutRef.value.show();
          }         
        }else{
          showTimeAlert("Nenhum serviço encontrado com essa OS", "error")
        }       

        passingVehicles.value[index].buttonLoading = true
      }
    }

    function newTask(agendamento) {
      if (concessionariaInfo.value) {
        initialForm.value = {
          codAgendamento: agendamento.idagendamento,
          detalhes: agendamento.obs,
          taskType: "",
          cliente: agendamento.cliente,
          placa: agendamento.placa,
          chassi: agendamento.chassi,
          taskHourEntrega: "",
          taskHourRecepcao: agendamento.horaagendada,
          formType: "New",
          taskEndHour: "",
          taskDateTratada: agendamento.dataagendamento,
          taskDate: moment(agendamento.dataagendamento, "DD/MM/YYYY"),
          taskStartHour: agendamento.horaagendada,
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          dataAgendamento: `${agendamento.dataagendamento} ${agendamento.horaagendada}`,
          marca: agendamento.marca,
          modelo: agendamento.modelo,
          consultorAgendado: agendamento.consultorcodigo ?? agendamento.consultor_agendado,
          consultorAgendadoTratado: agendamento.consultor_agendado,
          osAberta: agendamento.osaberta
        }
      }

      refModalNew.value.show();
    };

    return {
      closeModalServicosAgendados,
      openModalServicosAgendados,
      initialForm,
      moment,
      refModalNew,
      agendamentos,
      newTask,
      emiteSuccess,
      dataFiltrada,
      newServicePassing,
      loading,
      passingVehicles,
      formServicePassing,
    };
  },
});
