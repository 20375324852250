
import { computed, defineComponent, onMounted, ref, watch, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { Modal } from "bootstrap";
import { getHorariosOcupadosMecanicos, deletarAgendamento, programarServicoParalizado } from "@/services/AgendaService";
import useAlert from "@/composables/Alert";
import { config, enums } from "@/views/agenda-virtual/ConfigEvents";
import { useStore } from "vuex";
import AuthService from "@/services/AuthService";

export default defineComponent({
  name: "edit-event-modal",
  components: {},

  props: {
    eventInfo: Object,
    showStatusControl: {
      type: Boolean,
      default: false
    },
    showEntregaControl: {
      type: Boolean,
      default: false
    },
    editable: Boolean,
  },

  emits: ["addTask", "editTask", "success", "deleted"],

  setup(props, { emit }) {
    const store = useStore();
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const showContent = ref<any>(true);
    const initialTaskEndHour = ref("");
    const initialHourEntrega = ref("");
    const loadingBtn = ref(false);
    const changesServiceInfo = ref(false);
    const userInfo = AuthService.getUsuarioAuth();
    const programaAgenda = userInfo.programaAgenda;
    const horariosOcupadosDias: any = ref([])
    const horarios = reactive({
      horaAbertura: "08:00",
      horaFechamento: "18:00"
    })
    const targetDataRefStart: any = ref({
      codServico: "",
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      chassi: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      endWithout: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
      tempoAdicional: "",
      tempoAdicionalSelected: "",
      codTipoServico: "",
    });
    const targetData: any = ref({
      codServico: "",
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      chassi: "",
      placa: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      endWithout: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
      tempoAdicional: "",
      tempoAdicionalSelected: "",
      codTipoServico: "",
    });
    const defaultInitialTimes: any = ref(geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento));
    const defaultFinalTimes: any = ref([]);
    const usaChecklist = ref('')
    const aberturaOS = ref<number | string>('')
    const dms = ref<number>(0)

    function horariosQuadro() {
      const concessionaria = store.getters.concessionariaSelectedInfo
      usaChecklist.value = concessionaria.usaChecklist
      aberturaOS.value = concessionaria.osdms
      dms.value = concessionaria.dms
      if (!(Object.keys(concessionaria).length === 0)) {
        horarios.horaAbertura = moment(concessionaria.horaAbertura, "H").format("HH:mm")
        horarios.horaFechamento = moment(concessionaria.horaFechamento, "H").format("HH:mm")
        defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento)
      }
    }

    function geraDefaultTimes(minTime = "07:00", maxTime = "19:00", step = 15, showLastTime = false) {
      const refTrueLastTime = moment(maxTime, "HH:mm");
      let refLastTime = refTrueLastTime.clone();
      if (!showLastTime) {
        refLastTime.subtract(step, "minutes");
      }

      let auxDefaultTimes: any = [];
      let auxTime = moment(minTime, "HH:mm");
      auxTime.subtract(step, "minutes");

      while (auxTime.clone().isBefore(refLastTime)) {
        auxTime.add(step, "minutes");
        const nextTime = auxTime.clone();

        if (nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))) {
          auxDefaultTimes.push({
            text: nextTime.format("HH:mm"),
            value: nextTime.format("HH:mm"),
            disabled: false
          });
        }
      }

      return auxDefaultTimes;
    }

    function closeModal() {
      const modal: Modal | null = document.getElementById(`kt_modal_event_edit_info_paralisado`);
      hideModal(modal);
    };

    function verifyChanges() {
      if (!formRef.value) {
        return;
      }

      if (targetData.value.codTipoServico != targetDataRefStart.value.codTipoServico) {
        changesServiceInfo.value = true;
        return;
      }

      if (targetData.value.codTecnico != targetDataRefStart.value.codTecnico) {
        changesServiceInfo.value = true;
        return;
      }

      if (targetData.value.taskStartHour != targetDataRefStart.value.taskStartHour) {
        changesServiceInfo.value = true;
        return;
      }

      if (targetData.value.taskEndHour != targetDataRefStart.value.taskEndHour) {
        changesServiceInfo.value = true;
        return;
      }

      if (moment(targetData.value.taskDate).format("YYYY-MM-DD") != moment(targetDataRefStart.value.taskDate).format("YYYY-MM-DD")) {
        changesServiceInfo.value = true;
        return;
      }

      if (moment(targetData.value.taskEndDate).format("YYYY-MM-DD") != moment(targetDataRefStart.value.taskEndDate).format("YYYY-MM-DD")) {
        changesServiceInfo.value = true;
        return;
      }

      changesServiceInfo.value = false;
    }

    function removeAcentos(name, toLowerCase = false) {
      if (!name) {
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    watch(() => props.eventInfo, () => {
      const eventInfo: any = props.eventInfo;
      const eventConfig = config.events[eventInfo.codTipoServico - 1];
      const tecnicoInfo = tecnicos.value.find(tecnico => {
        const mecanico: any = tecnico;
        return mecanico.codMecanico == eventInfo.codTecnico;
      });

      let consultorAgendadoTratado = store.getters.consultoresList.find(consultor => {
        return removeAcentos(consultor.intConsultor, true) == removeAcentos(eventInfo.consultorAgendado, true)
      })?.nome;

      consultorAgendadoTratado = consultorAgendadoTratado ?? eventInfo.consultorAgendado;

      const info = {
        codConcessionaria: eventInfo.codConcessionaria,
        codServico: eventInfo.codServico,
        aguarda: eventInfo.aguarda,
        codAgendamento: eventInfo.codAgendamento,
        detalhes: eventInfo.observacao,
        cliente: eventInfo.cliente,
        placa: eventInfo.placa,
        chassi: eventInfo.chassi,
        taskType: eventConfig.titleExtended,
        codTipoServico: eventInfo.codTipoServico,
        taskDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        taskDateTratada: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
        taskStartDate: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
        taskEndDate: moment(eventInfo.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format(),
        taskStartHour: moment(eventInfo.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskEndHour: moment(eventInfo.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        endWithout: moment(eventInfo.endWithout, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskHourEntrega: moment(eventInfo.dataEntrega, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        taskHourRecepcao: moment(eventInfo.dataRecepcao, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        tecnico: tecnicoInfo?.nome ?? "",
        codTecnico: eventInfo.codTecnico,
        dataAgendamento: moment(eventInfo.dataAgendamento, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
        consultorAgendado: eventInfo.consultorAgendado,
        consultorAgendadoTratado,
        marca: eventInfo.marca,
        modelo: eventInfo.modelo,
        osAberta: eventInfo.osAberta,
        percentualRealizado: eventInfo.percentualRealizado,
        tempoAdicional: eventInfo.tempoAdicional,
        tempoAdicionalSelected: eventInfo.tempoAdicional,
        status: eventInfo.status,
        statusName: enums.status.find(status => status.value == eventInfo.status)?.label,
        taskStartExecucao: eventInfo.dataInicioServicoExecucao ? moment(eventInfo.dataInicioServicoExecucao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm") : null,
        taskEndExecucao: eventInfo.dataFinalServicoExecucao ? moment(eventInfo.dataFinalServicoExecucao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm") : null
      };

      targetDataRefStart.value = Object.assign({}, info);
      targetData.value = Object.assign({}, info);
      changesServiceInfo.value = false;

      verificaHorariosOcupados();
      verificaHorarioInicial();
    });

    const tecnicoOptions: any = ref([]);
    const tecnicos: any = ref([]);
    const horariosOcupados: any = ref([]);
    const modeloName = ref("");

    store.watch(() => store.getters.modeloAgendaName, () => {
      modeloName.value = store.getters.modeloAgendaName;
    });

    store.watch(() => store.getters.mecanicosList, () => {
      tecnicos.value = store.getters.mecanicosList;

      trataMecanicos();
    }, { deep: true });

    onMounted(() => {
      horariosQuadro()

      if (store.getters.modeloAgendaName) {
        modeloName.value = store.getters.modeloAgendaName;
      }

      if (store.getters.mecanicosList.length > 0) {
        tecnicos.value = store.getters.mecanicosList;

        trataMecanicos();
      }
    });

    function trataMecanicos() {
      const mecanicos = store.getters.mecanicosList;
      tecnicoOptions.value = mecanicos.map(tecnico => {
        const option = {
          text: tecnico.nome,
          value: tecnico.codMecanico
        }

        return option;
      })
    }

    store.watch(() => store.getters.concessionariasList, () => {
      if (store.getters.concessionariasList) horariosQuadro()
    })

    watch(() => targetData.value.taskType, () => {
      if (targetData.value.taskType) {
        const refOption = tipoOptions.find(option => option.value === targetData.value.taskType);
        showContent.value = refOption?.config.hasContent;
      } else {
        showContent.value = false;
      }
    });

    const tipoOptions = [
      {
        text: "TESTE",
        value: 1,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#682E21",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RET (Retorno)",
        value: 2,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "black",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "AL (Alinhamento)",
        value: 3,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "orange",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "DIAG (Diagnóstico)",
        value: 4,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "red",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "QS (Quick Service)",
        value: 5,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#1F3059",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "MP (Manutenção Periódica)",
        value: 6,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "#darkgreen",
          headerTextColor: "#ffffff"
        },
      },
      {
        text: "RG (Reparo Geral)",
        value: 7,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "lawngreen",
          headerTextColor: "#000000"
        },
      },
      {
        text: "AC (Acessórios)",
        value: 8,
        config: {
          hasContent: true,
          hasFooter: true,
          bgColor: "yellow",
          headerTextColor: "#ffffff"
        },
      },
    ];

    const rules = ref({
      taskDate: [
        {
          required: true,
          message: "Selecione a data do agendamento",
          trigger: "change",
        },
      ],
      taskStartHour: [
        {
          required: true,
          message: "Selecione o horário inicial de realização do serviço",
          trigger: "change",
        },
      ],
      taskEndHour: [
        {
          required: true,
          message: "Selecione o horário final de realização do serviço",
          trigger: "change",
        },
      ],
      taskHourEntrega: [  
        { 
          required: true,
          message: "Selecione o horário de recepção do cliente",
          trigger: "blur"
        }
      ]
    });


    async function getHorariosOcupados(codConcessionaria, dataFormatada) {
      return await getHorariosOcupadosMecanicos(codConcessionaria, dataFormatada);
    }

    async function verificaHorariosOcupados() {
      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const saoIguais = dataInicial.isSame(dataFinal, 'day');

      const dataInicialFormatada = dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFinal.format("YYYY-MM-DD");

      if (saoIguais || usaChecklist.value != '147') {
        try {
          const response = await getHorariosOcupadosMecanicos(targetData.value.codConcessionaria, dataInicialFormatada);
          horariosOcupados.value = response;
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        }
      } else {
        try {
          const [responseDataInicial, responseDataFinal] = await Promise.all([
            getHorariosOcupados(targetData.value.codConcessionaria, dataInicialFormatada),
            getHorariosOcupados(targetData.value.codConcessionaria, dataFinalFormatada)
          ]);

          horariosOcupadosDias.value = responseDataFinal
          horariosOcupados.value = responseDataInicial;
        } catch (error) {
          showTimeAlert("Não foi possível completar a solicitação", "error");
          closeModal()
        }
      }


      desabilitaHorariosOcupados();
    };

    async function desabilitaHorariosOcupados() {
      defaultInitialTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
      verificaHorarioInicial();

      const dataInicial = moment(targetData.value.taskDate); // Use taskDate para a data inicial
      const dataFinal = moment(targetData.value.taskEndDate);

      //caso a data inicial for maior que a data final
      if (dataInicial.isAfter(dataFinal)) {
        targetData.value.taskEndDate = targetData.value.taskDate
      }

      // Comparar apenas o dia, mês e ano, ignorando a hora, minuto e segundo
      const dataInicialEFinalIgual = dataInicial.isSame(dataFinal, 'day');

      let auxHorarioFinalOcupado = false;
      if (targetData.value.tecnico) {

        const horariosOcupadosMecanicoSelecionadoDataFinal = horariosOcupadosDias.value.filter(horario => horario.codTecnico == targetData.value.codTecnico && horario.codServico != targetData.value.codServico);
        const horariosOcupadosMecanicoSelecionadoDataInicial = horariosOcupados.value.filter(horario => horario.codTecnico == targetData.value.codTecnico && horario.codServico != targetData.value.codServico);

        const dataComparacao = moment(targetData.value.taskEndDate).startOf('day');

        const todasIguais = horariosOcupadosMecanicoSelecionadoDataInicial.every(item => {
          const dataFinalServico = moment(item.dataFinalServico).startOf('day');
          return dataFinalServico.isSame(dataComparacao);
        });

        if (dataInicialEFinalIgual || usaChecklist.value != '147') {

          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
            let auxStartTime: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxStartTime = moment(auxStartTime, "HH:mm");

            let auxEndTime: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxEndTime = moment(auxEndTime, "HH:mm");

            const auxDefaultInitialTimes = defaultInitialTimes.value.map(time => {
              if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            })
            defaultInitialTimes.value = auxDefaultInitialTimes;

            if (!auxHorarioFinalOcupado) {
              defaultFinalTimes.value = defaultFinalTimes.value.map(time => {
                if (auxHorarioFinalOcupado || horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]")) {
                  auxHorarioFinalOcupado = true;

                  return {
                    ...time,
                    disabled: true
                  };
                } else {
                  return time;
                }
              }).filter(time => !time.disabled);
            }

            if (todasIguais) {
              if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(targetData.value.taskStartHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "[)")) {
                targetData.value.taskStartHour = "";
                targetData.value.taskEndHour = "";
              } else if (
                moment(targetData.value.taskEndHour, "HH:mm").isBetween(auxStartTime, auxEndTime, undefined, "(]") ||
                (auxEndTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "(]") &&
                  auxStartTime.isBetween(moment(targetData.value.taskStartHour, "HH:mm"), moment(targetData.value.taskEndHour, "HH:mm"), undefined, "[)")
                )
              ) {
                targetData.value.taskEndHour = "";
              }
            }
          })

        } else {

          let lastBlockedEndTime: any = null;
          defaultFinalTimes.value = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);

          horariosOcupadosMecanicoSelecionadoDataInicial.forEach(horario => {
            let auxStartTime2: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxStartTime2 = moment(auxStartTime2, "HH:mm");

            let auxEndTime2: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxEndTime2 = moment(auxEndTime2, "HH:mm");

            //aqui pegamos os ultimo horario ocupado para bloquear os anteriores
            defaultInitialTimes.value = defaultInitialTimes.value.map((time, index) => {
              if (horario.codTipoServico == 10 || horario.codTipoServico == 11 || moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "[)")) {
                lastBlockedEndTime = lastBlockedEndTime ? moment.max(lastBlockedEndTime, auxEndTime2) : auxEndTime2;
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            });

          })

          //logica para pega o ultimo horario ocupado e bloquear todos antes dele 
          if (lastBlockedEndTime) {
            defaultInitialTimes.value = defaultInitialTimes.value.map((time) => {
              if (moment(time.text, "HH:mm").isSameOrBefore(lastBlockedEndTime, 'minute')) {
                return {
                  ...time,
                  disabled: true
                };
              } else {
                return time;
              }
            });
          }

          const novasHoras = geraDefaultTimes(horarios.horaAbertura, horarios.horaFechamento);
          let isFirstIteration = true;

          horariosOcupadosMecanicoSelecionadoDataFinal.forEach(horario => {
            let auxStartTime2: any = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxStartTime2 = moment(auxStartTime2, "HH:mm");

            let auxEndTime2: any = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            auxEndTime2 = moment(auxEndTime2, "HH:mm");

            // todos horarios depois do primeiro horario ocupado e bloqueado
            defaultFinalTimes.value = novasHoras.map(time => {
              if (
                horario.codTipoServico == 10 ||
                horario.codTipoServico == 11 ||
                (moment(time.text, "HH:mm").isBetween(auxStartTime2, auxEndTime2, undefined, "(]") && isFirstIteration)
              ) {
                isFirstIteration = false;
                return {
                  ...time,
                  disabled: true,
                };
              } else if (!isFirstIteration) {
                return {
                  ...time,
                  disabled: true,
                };
              } else {
                return time;
              }
            });
          });
        }
      }

    }

    async function deletarEvento() {
      Swal.fire({
        title: "Deseja confirmar a exclusão do agendamento?",
        text: "A ação não poderá ser desfeita",
        position: 'center',
        showConfirmButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          loadingBtn.value = true;
          const postData = {
            codServico: [targetData.value.codServico]
          };
          deletarAgendamento(postData)
            .then(response => {
              showTimeAlert("O agendamento foi deletado com sucesso!");
              emit("success");
              closeModal();
            })
            .catch(
              () => showTimeAlert("Não foi possível completar a solicitação", "error")
            ).finally(() => {
              loadingBtn.value = false;
            });
        }
      });
    }

    function desabilitaDatasPassadas(data) {
      return moment(data).diff(moment(), 'days') < 0;
    };

    function verificaHorarioInicial() {
      const startHour = moment(targetData.value.taskStartHour, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskEndHour, "HH:mm");

      defaultFinalTimes.value = geraDefaultTimes(auxStartHour.clone().add(15, "minutes").format("HH:mm"), horarios.horaFechamento, 15, true);

      if (usaChecklist.value !== '147') {
        if (endHour && endHour.isBefore(startHour)) {
          targetData.value.taskEndHour = null;
        }
      }
    }

    function verificaHorarioInicialRecepcao() {
      const startHour = moment(targetData.value.taskHourRecepcao, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(targetData.value.taskHourEntrega, "HH:mm");

      initialHourEntrega.value = auxStartHour.clone().add(15, "minutes").format("HH:mm");

      if (endHour && endHour.isBefore(startHour)) {
        targetData.value.taskHourEntrega = null;
      }
    }

    async function saveEditTask(postData) {
      try {
        const response = await programarServicoParalizado(postData);

        if (response.ocupado) {
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11) {
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          });

          return;
        }

        emit("success");
        showTimeAlert("As alterações foram realizadas com sucesso!");
        closeModal();
      } catch (e) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          Swal.fire({
            title: "Deseja confirmar as alterações realizadas?",
            // text: "O serviço será modificado",
            position: 'center',
            showConfirmButton: true,
            reverseButtons: true,
            allowOutsideClick: true,
            showCancelButton: true,
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
              cancelButton: "btn btn-light me-3",
            },
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            focusConfirm: false,
          }).then((result) => {
            if (result.isConfirmed) {
              loading.value = true;

              const dataInicialTratada = moment(targetData.value.taskDate).format("YYYY-MM-DD");

              const postData = {
                codServico: targetData.value.codServico,
                codTipoServico: targetData.value.codTipoServico,
                dataInicioServico: moment(dataInicialTratada + " " + targetData.value.taskStartHour).format("YYYY-MM-DD HH:mm:ss"),
                dataFinalServico: moment(dataInicialTratada + " " + targetData.value.taskEndHour).format("YYYY-MM-DD HH:mm:ss"),
                codTecnico: targetData.value.codTecnico,
                osAberta: targetData.value.osAberta ?? null,
                dataEntrega:  targetData.value.dataEntrega ,
              }

              saveEditTask(postData);
            }
          });
        }
      });
    };

    return {
      tecnicoOptions,
      formRef,
      loading,
      targetData,
      showContent,
      rules,
      enums,
      submit,
      initialTaskEndHour,
      initialHourEntrega,
      verificaHorarioInicial,
      verificaHorarioInicialRecepcao,
      tipoOptions,
      ptBr,
      closeModal,
      deletarEvento,
      loadingBtn,
      changesServiceInfo,
      verifyChanges,
      desabilitaDatasPassadas,
      modeloAgendaName: computed(() => store.getters.modeloAgendaName),
      verificaHorariosOcupados,
      defaultInitialTimes,
      defaultFinalTimes,
      desabilitaHorariosOcupados,
      modeloName,
      programaAgenda,
      usaChecklist,
      aberturaOS,
      dms
    };
  },
});
